export const WORDS = [
"аадар",
"аадар",
"аажам",
"аалин",
"ааяма",
"ааяха",
"абаса",
"абаха",
"абгай",
"абдар",
"абтай",
"абяан",
"агаар",
"адага",
"адаха",
"адуун",
"ажахы",
"айдар",
"айком",
"айлга",
"аймаг",
"айраг",
"алаха",
"албан",
"албан",
"алгад",
"алдар",
"алдуу",
"алмай",
"алхам",
"амида",
"амиды",
"амтан",
"амһар",
"аншан",
"араан",
"арбан",
"аргал",
"арһан",
"арһан",
"аршам",
"арьял",
"арюун",
"атаан",
"аһаха",
"аһаха",
"ашаан",
"ашаха",
"аягүй",
"аялга",
"аялга",
"аялха",
"аянай",
"аярха",
"аятай",
"бааза",
"багса",
"багта",
"багша",
"бадма",
"бадха",
"байра",
"байха",
"балар",
"балга",
"балта",
"бараг",
"баран",
"барас",
"барма",
"барюу",
"басни",
"биирэ",
"билта",
"бишэн",
"богдо",
"бодол",
"болод",
"бообо",
"боожо",
"боожо",
"боомо",
"боомо",
"боори",
"боохо",
"бороо",
"борсо",
"бохёо",
"бохир",
"бөөрэ",
"будаа",
"будаг",
"будда",
"бузар",
"булаг",
"булад",
"булай",
"булан",
"булта",
"булюу",
"буляа",
"буруу",
"буруу",
"бурхи",
"бусад",
"бууза",
"бууса",
"бууха",
"бухал",
"бухын",
"бушуу",
"бүдхэ",
"бүдэн",
"бүлгэ",
"бүлин",
"бүлын",
"бүлэг",
"бүлюу",
"бүтүү",
"бүтэн",
"бүхэн",
"бэеын",
"бэлиг",
"бэлэг",
"бэлэй",
"бэлэн",
"бэрхэ",
"бэрхэ",
"бэшэг",
"бэшэд",
"бэшэн",
"габжа",
"гадар",
"гадна",
"газаа",
"газар",
"галай",
"ганга",
"ганга",
"ганза",
"ганса",
"гарай",
"гарза",
"гариг",
"гахай",
"годли",
"годон",
"гоёор",
"гоёхо",
"голой",
"голой",
"горьё",
"гуйха",
"гунан",
"гуниг",
"гуран",
"гурил",
"гурим",
"гутал",
"гуули",
"гушан",
"гүбээ",
"гүжэр",
"гүйсэ",
"гүйхэ",
"гүрөө",
"гүрэн",
"гүүжэ",
"гэгшэ",
"гэдэг",
"гэмтэ",
"гэнжэ",
"гэнтэ",
"гэнэн",
"гэршэ",
"гэрэй",
"гэрэл",
"гээхэ",
"гээшэ",
"дайда",
"далай",
"далан",
"дарга",
"дарха",
"дахин",
"домбо",
"домог",
"доодо",
"доошо",
"досоо",
"дунда",
"дурам",
"дуран",
"дутуу",
"дуһаа",
"дүлгэ",
"дүлии",
"дүлэн",
"дүрим",
"дүрөө",
"дүрсэ",
"дүтын",
"маани",
"магад",
"майла",
"малай",
"манай",
"манан",
"манжа",
"мансы",
"минаа",
"МИНИН",
"могой",
"модон",
"молор",
"молхи",
"морин",
"мохоо",
"мугуй",
"мужаа",
"мужуу",
"мунай",
"мунса",
"мууса",
"мухаа",
"мухар",
"мүйлэ",
"мүлшэ",
"мүлюу",
"мүнөө",
"мүнхэ",
"мүргэ",
"мүрэй",
"мүрэн",
"мүшэн",
"мүшэр",
"мэнгэ",
"мэндэ",
"мэндэ",
"мэтын",
"мэтэр",
"наада",
"наана",
"нааша",
"набша",
"нажар",
"найжа",
"найма",
"намаа",
"намаг",
"намар",
"нарай",
"нарин",
"нашан",
"наяад",
"ная(н)",
"нидхэ",
"ниихэ",
"нилха",
"нобшо",
"ногто",
"номин",
"нохой",
"нөөсэ",
"нөөхэ",
"нүгөө",
"нүгэл",
"нүүхэ",
"нүхэн",
"нүхэр",
"нэбтэ",
"нэгэн",
"нэдхэ",
"нээхэ",
"нюдэн",
"нютаг",
"нюуса",
"нюуха",
"нюһан",
"няаха",
"нягта",
"обтой",
"огсом",
"огтор",
"одоол",
"одхон",
"оёдол",
"оёлго",
"оёмол",
"озохо",
"ойгүй",
"ойгүй",
"ойлиг",
"ойрын",
"ойтой",
"ойтой",
"олбог",
"олгой",
"олоор",
"олохо",
"омоли",
"омһон",
"онгон",
"ондоо",
"оносо",
"онохо",
"оодон",
"ооһор",
"орбон",
"ордон",
"ормог",
"орохо",
"оршон",
"орьёл",
"охихо",
"ошохо",
"өөрсэ",
"өөхэн",
"өөһэд",
"пэтэн",
"саада",
"саана",
"сабуу",
"сабхи",
"сажан",
"сайха",
"салин",
"салир",
"самса",
"санха",
"сараа",
"сарам",
"сасаг",
"сасуу",
"саһан",
"сомон",
"сомоо",
"сонин",
"сонхо",
"соохи",
"сорго",
"сохом",
"сусал",
"сууха",
"сухал",
"сухал",
"сүгсэ",
"сүлөө",
"сэбэр",
"сэмбэ",
"сэргэ",
"сэрэг",
"сэсэг",
"сэсэн",
"сээжэ",
"тааха",
"табаг",
"табан",
"табан",
"табан",
"табин",
"тагша",
"тажаа",
"тайха",
"талхи",
"тамга",
"тамир",
"танай",
"танил",
"танха",
"тараг",
"тарим",
"тарни",
"тархи",
"тахал",
"тахил",
"тахим",
"тахир",
"тахяа",
"таһаг",
"тиимэ",
"тиишэ",
"тобшо",
"тобшо",
"толбо",
"толон",
"толто",
"тоног",
"тоо(н)",
"тоохо",
"торхо",
"тохир",
"тохой",
"тохом",
"тоһон",
"тугал",
"тулам",
"тулга",
"тураг",
"туужа",
"тууха",
"тухай",
"тушаа",
"түбэг",
"түбэй",
"түгэд",
"түйсэ",
"түлгэ",
"түлөө",
"түлэб",
"түлэг",
"түмэн",
"түмэр",
"түрии",
"түрмэ",
"түрүү",
"түрэл",
"түсэб",
"түүхэ",
"түүхэ",
"түхэл",
"түһөө",
"тэбшэ",
"тэгшэ",
"тэгэн",
"тэндэ",
"тэнэг",
"тэргэ",
"тэртэ",
"тэсэл",
"тээли",
"тээхэ",
"тээшэ",
"уггүй",
"углуу",
"угсаа",
"удаан",
"узуур",
"уйдал",
"уймар",
"уйтай",
"уйтан",
"улаан",
"улиха",
"улхан",
"улһан",
"унаха",
"унаха",
"ундан",
"унзад",
"уняар",
"урдын",
"уриха",
"уриха",
"урлал",
"уршаг",
"урьха",
"уряал",
"утаан",
"утаха",
"ууган",
"уужам",
"уунги",
"уураг",
"уурал",
"ухаан",
"ушааг",
"уялаа",
"уялга",
"уялга",
"уярха",
"уяхан",
"үбгэн",
"үбдэг",
"үбдэг",
"үбһэн",
"үбшэн",
"үглөө",
"үгэхэ",
"үдхэн",
"үдэхэ",
"үдэхэ",
"үдэшэ",
"үегүй",
"үелхэ",
"үетэй",
"үетэн",
"үеэлэ",
"үжэхэ",
"үзүүр",
"үзэхэ",
"үйлсэ",
"үйһэн",
"үлбэр",
"үлгэн",
"үлөөр",
"үлтэг",
"үлтэй",
"үлхөө",
"үлыхэ",
"үльмы",
"үлэхэ",
"үмдэн",
"үмсын",
"үнгын",
"үнгэн",
"үнгэр",
"үндэр",
"үнеэн",
"үншэн",
"үргэл",
"үргэм",
"үргэн",
"үреэл",
"үрүүл",
"үрэхэ",
"үсөөн",
"үстэр",
"үтэхэ",
"үүдэн",
"үүлэн",
"үүргэ",
"үхэсэ",
"үхэхэ",
"үһөөн",
"егтэй",
"едүүн",
"елүүр",
"елыхэ",
"емшэг",
"ендэр",
"ерүүл",
"ерэхэ",
"ерэхэ",
"ерээд",
"ехэдэ",
"ехэсэ",
"ехээр",
"ёлмын",
"ёнхор",
"ёохор",
"ёһодо",
"ёһоор",
"ёһото",
"жааха",
"жабар",
"жадха",
"жалга",
"жаран",
"жодоо",
"жолоо",
"жолоо",
"жороо",
"журам",
"жэбын",
"жэгдэ",
"жэжэг",
"жэлэй",
"жэмэс",
"жэрхи",
"жэрэм",
"жэшээ",
"заата",
"зааха",
"зааха",
"забда",
"загал",
"зайха",
"залаа",
"залан",
"залин",
"залуу",
"замаг",
"замай",
"замби",
"занга",
"зарга",
"зарим",
"заряа",
"засаг",
"затан",
"захил",
"захын",
"захяа",
"заһал",
"заяан",
"золиг",
"зоохо",
"зорго",
"зориг",
"зосоо",
"зотой",
"зохид",
"зөөри",
"зөөхэ",
"зубхи",
"зугаа",
"зулаг",
"зулай",
"зулгы",
"зулын",
"зунай",
"зураг",
"зурам",
"зутан",
"зутар",
"зуужа",
"зуура",
"зууха",
"зүгын",
"зүгэй",
"зүдэг",
"зүжэг",
"зүйхэ",
"зүлгэ",
"зүлхэ",
"зүншэ",
"зургэ",
"зүрюу",
"зүүхэ",
"зүһэм",
"зүһэн",
"зэдэй",
"зэргэ",
"зэтэр",
"зээли",
"иигэд",
"иигэд",
"иигэн",
"илаха",
"илгаа",
"илдам",
"ираха",
"иргуу",
"хаана",
"хааха",
"хабан",
"хабар",
"хадаа",
"хадаг",
"хадам",
"хадын",
"хажар",
"хажуу",
"хайра",
"хайха",
"хайша",
"хайша",
"хайша",
"халба",
"халта",
"халха",
"хамаг",
"хамар",
"хамба",
"хамсы",
"хамта",
"хамха",
"ханза",
"ханын",
"хараа",
"харад",
"харам",
"харгы",
"харза",
"харин",
"харуу",
"харха",
"харша",
"харша",
"харья",
"харюу",
"хасаг",
"хасар",
"хатан",
"хатар",
"хатар",
"хатуу",
"хахад",
"хашаа",
"хашаг",
"хашан",
"хаяма",
"хаяса",
"хаяха",
"хёрхо",
"хилар",
"хилэн",
"химда",
"хирүү",
"хирээ",
"хитад",
"хобоо",
"хобто",
"хожом",
"хойно",
"хойто",
"хойто",
"хойшо",
"холхи",
"холхи",
"холын",
"хомор",
"хонин",
"хоног",
"хонхо",
"хорёо",
"хорзо",
"хориг",
"хорим",
"хорин",
"хорон",
"хохир",
"хошоо",
"хошоо",
"хөөхэ",
"худаг",
"худал",
"хужар",
"хулан",
"хун(г)",
"хурал",
"хурим",
"хурса",
"хурхи",
"хурын",
"хурын",
"хуули",
"хууха",
"хууха",
"хуһан",
"хушын",
"хүбөө",
"хүбшэ",
"хүбэн",
"хүгүй",
"хүдөө",
"хүдөө",
"хүдүү",
"хүдэр",
"хүжэр",
"хүлэг",
"хүнды",
"хүндэ",
"хүндэ",
"хүнэг",
"хүнэй",
"хүрдэ",
"хүреэ",
"хүрин",
"хүсэд",
"хүсэл",
"хүхэн",
"хүшэр",
"хэбэд",
"хэдэр",
"хэжэг",
"хэзээ",
"хэлгэ",
"хэлэн",
"хэмхэ",
"хэмэл",
"хэнэй",
"хэрэг",
"хэрэм",
"хэсүү",
"хэсэг",
"хэтын",
"хэһэг",
"хээли",
"хээрэ",
"хюрөө",
"хюруу",
"хямда",
"һабаа",
"һабар",
"һайса",
"һалаа",
"һалан",
"һалан",
"һалга",
"һамар",
"һанал",
"һарын",
"һарюу",
"һахал",
"һаяар",
"һиихэ",
"һобой",
"һомон",
"һонин",
"һонор",
"һохор",
"һубаг",
"һубад",
"һубай",
"һудал",
"һудал",
"һураг",
"һургы",
"һуури",
"һууха",
"һүбээ",
"һүдэн",
"һүзэг",
"һүлдэ",
"һүнэй",
"һүрөө",
"һүрэг",
"һүүжэ",
"һүүшэ",
"һэбхи",
"һэбхи",
"һэжэг",
"һэлмэ",
"һэлмэ",
"һэмжэ",
"һэнэг",
"һэрээ",
"һэтэр",
"шаара",
"шааха",
"шабай",
"шабар",
"шабха",
"шагай",
"шагта",
"шадал",
"шадар",
"шажан",
"шалиг",
"шанаа",
"шанал",
"шанар",
"шан(г)",
"шанга",
"шанха",
"шарад",
"шарай",
"шарга",
"шарга",
"шарха",
"шатар",
"шахам",
"шахуу",
"шашаг",
"шиирэ",
"шинии",
"шолмо",
"шонын",
"шонын",
"шорой",
"шугам",
"шулуу",
"шунал",
"шураг",
"шуран",
"шууха",
"шүбгэ",
"шүдэн",
"шүдэр",
"шүлхы",
"шүлэг",
"шүлэн",
"шүрхы",
"шүрэб",
"шүүхэ",
"шүүхэ",
"шүүхэ",
"шухэр",
"шэбхэ",
"шэбхэ",
"шэбэр",
"шэбэр",
"шэбэр",
"шэбээ",
"шэгшы",
"шэлбэ",
"шэлэй",
"шэмхэ",
"шэмэг",
"шэнги",
"шэнжэ",
"шэрхи",
"шэрэм",
"шэрээ",
"шэхэн",
"шээхэ",
"эбгүй",
"эблэл",
"эгэшэ",
"эгээл",
"эдеэн",
"эдихэ",
"эдлэл",
"эдюур",
"элбэг",
"элдин",
"элдин",
"элдэб",
"элеэр",
"элитэ",
"элихэ",
"элүүр",
"элһэн",
"элшэн",
"эльбэ",
"элэхэ",
"элюур",
"эмгэй",
"эмгэн",
"эмниг",
"эмээл",
"энгэр",
"эндүү",
"эрдэм",
"эреэн",
"эржэн",
"эритэ",
"эрихэ",
"эрлиг",
"эрмэг",
"эртын",
"эрхим",
"эрьюу",
"эсэгэ",
"эсэхэ",
"эһэхэ",
"эшэгы",
"эшэхэ",
"эшээн",
"ээлгэ",
"ээрүү",
"юрөөл",
"юумэн",
"юушье",
"юһэтэ",
"яарал",
"яарса",
"яаруу",
"яахаа",
"яаһан",
"яашье",
"ябаса",
"ябаха",
"ябууд",
"ягаан",
"ядаха",
"ямаан",
"яндан",
"яряан",
"яһала",
"яярха",
"абзац",
"бугын",
"тунга",
"чипса",
"сахим",
"банан",
"наран",
"манга",
"шорон",
"сэдэб",
"Минии",
"Ангир",
"уудам",
"банша",
"хутаг",
"хэшэг",
"Росси",
"һааха",
"долон",
"болоо",
"нялха",
"озгой",
"бүксэ",
"зоной",
"үнтэй",
"зайла",
"зэдын",
"Яруун",
"абааб",
"ерээб",
"хүхые",
"алтан",
"гараа",
"яабая",
"баряа",
"үдхөө",
"мэдхэ",
]